export const UserActionTypes = {
  SET_ITEM_TYPE: 'SET_ITEM_TYPE',

  SET_CURRENT_USER: 'SET_CURRENT_USER',

  CHECK_USER_SESSION: 'CHECK_USER_SESSION',

  SIGN_IN_START: 'SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

  // User Watched
  POST_USER_WATCHED_START: 'POST_USER_WATCHED_START',
  POST_USER_WATCHED_SUCCESS: 'POST_USER_WATCHED_SUCCESS',
  POST_USER_WATCHED_FAILURE: 'POST_USER_WATCHED_FAILURE',

  GET_USER_WATCHED_START: 'GET_USER_WATCHED_START',
  GET_USER_WATCHED_SUCCESS: 'GET_USER_WATCHED_SUCCESS',
  GET_USER_WATCHED_FAILURE: 'GET_USER_WATCHED_FAILURE',

  DELETE_USER_WATCHED_START: 'DELETE_USER_WATCHED_START',
  DELETE_USER_WATCHED_SUCCESS: 'DELETE_USER_WATCHED_SUCCESS',
  DELETE_USER_WATCHED_FAILURE: 'DELETE_USER_WATCHED_FAILURE',

  // User Collection
  POST_USER_COLLECTION_START: 'POST_USER_COLLECTION_START',
  POST_USER_COLLECTION_SUCCESS: 'POST_USER_COLLECTION_SUCCESS',
  POST_USER_COLLECTION_FAILURE: 'POST_USER_COLLECTION_FAILURE',

  GET_USER_COLLECTION_START: 'GET_USER_COLLECTION_START',
  GET_USER_COLLECTION_SUCCESS: 'GET_USER_COLLECTION_SUCCESS',
  GET_USER_COLLECTION_FAILURE: 'GET_USER_COLLECTION_FAILURE',

  DELETE_USER_COLLECTION_START: 'DELETE_USER_COLLECTION_START',
  DELETE_USER_COLLECTION_SUCCESS: 'DELETE_USER_COLLECTION_SUCCESS',
  DELETE_USER_COLLECTION_FAILURE: 'DELETE_USER_COLLECTION_FAILURE',

  // User Watchlist
  POST_USER_WATCHLIST_START: 'POST_USER_WATCHLIST_START',
  POST_USER_WATCHLIST_SUCCESS: 'POST_USER_WATCHLIST_SUCCESS',
  POST_USER_WATCHLIST_FAILURE: 'POST_USER_WATCHLIST_FAILURE',

  GET_USER_WATCHLIST_START: 'GET_USER_WATCHLIST_START',
  GET_USER_WATCHLIST_SUCCESS: 'GET_USER_WATCHLIST_SUCCESS',
  GET_USER_WATCHLIST_FAILURE: 'GET_USER_WATCHLIST_FAILURE',

  DELETE_USER_WATCHLIST_START: 'DELETE_USER_WATCHLIST_START',
  DELETE_USER_WATCHLIST_SUCCESS: 'DELETE_USER_WATCHLIST_SUCCESS',
  DELETE_USER_WATCHLIST_FAILURE: 'DELETE_USER_WATCHLIST_FAILURE',

  // Update User Settings
  UPDATE_USER_DATA_START: 'UPDATE_USER_DATA_START',
  UPDATE_USER_DATA_SUCCESS: 'UPDATE_USER_DATA_SUCCESS',
  UPDATE_USER_DATA_FAILURE: 'UPDATE_USER_DATA_FAILURE',
};
