export const TVActionTypes = {
  FETCH_TV_AIRINGTODAY_START: 'FETCH_TV_AIRINGTODAY_START',
  FETCH_TV_AIRINGTODAY_SUCCESS: 'FETCH_TV_AIRINGTODAY_SUCCESS',
  FETCH_TV_AIRINGTODAY_FAILURE: 'FETCH_TV_AIRINGTODAY_FAILURE',

  FETCH_TV_POPULAR_START: 'FETCH_TV_POPULAR_START',
  FETCH_TV_POPULAR_SUCCESS: 'FETCH_TV_POPULAR_SUCCESS',
  FETCH_TV_POPULAR_FAILURE: 'FETCH_TV_POPULAR_FAILURE',

  FETCH_TV_ONTHEAIR_START: 'FETCH_TV_ONTHEAIR_START',
  FETCH_TV_ONTHEAIR_SUCCESS: 'FETCH_TV_ONTHEAIR_SUCCESS',
  FETCH_TV_ONTHEAIR_FAILURE: 'FETCH_TV_ONTHEAIR_FAILURE',

  FETCH_TV_TOPRATED_START: 'FETCH_TV_TOPRATED_START',
  FETCH_TV_TOPRATED_SUCCESS: 'FETCH_TV_TOPRATED_SUCCESS',
  FETCH_TV_TOPRATED_FAILURE: 'FETCH_TV_TOPRATED_FAILURE',

  FETCH_TV_GENRE_START: 'FETCH_TV_GENRE_START',
  FETCH_TV_GENRE_SUCCESS: 'FETCH_TV_GENRE_SUCCESS',
  FETCH_TV_GENRE_FAILURE: 'FETCH_TV_GENRE_FAILURE',

  FETCH_TV_DETAILS_START: 'FETCH_TV_DETAILS_START',
  FETCH_TV_DETAILS_SUCCESS: 'FETCH_TV_DETAILS_SUCCESS',
  FETCH_TV_DETAILS_FAILURE: 'FETCH_TV_DETAILS_FAILURE',

  FETCH_TV_CREDITS_START: 'FETCH_TV_CREDITS_START',
  FETCH_TV_CREDITS_SUCCESS: 'FETCH_TV_CREDITS_SUCCESS',
  FETCH_TV_CREDITS_FAILURE: 'FETCH_TV_CREDITS_FAILURE',

  FETCH_TV_TRAILERS_START: 'FETCH_TV_TRAILERS_START',
  FETCH_TV_TRAILERS_SUCCESS: 'FETCH_TV_TRAILERS_SUCCESS',
  FETCH_TV_TRAILERS_FAILURE: 'FETCH_TV_TRAILERS_FAILURE',

  FETCH_TV_REVIEWS_START: 'FETCH_TV_REVIEWS_START',
  FETCH_TV_REVIEWS_SUCCESS: 'FETCH_TV_REVIEWS_SUCCESS',
  FETCH_TV_REVIEWS_FAILURE: 'FETCH_TV_REVIEWS_FAILURE',

  FETCH_TV_TRENDING_START: 'FETCH_TV_TRENDING_START',
  FETCH_TV_TRENDING_SUCCESS: 'FETCH_TV_TRENDING_SUCCESS',
  FETCH_TV_TRENDING_FAILURE: 'FETCH_TV_TRENDING_FAILURE',

  FETCH_TV_TRENDING_TODAY_START: 'FETCH_TV_TRENDING_TODAY_START',
  FETCH_TV_TRENDING_TODAY_SUCCESS: 'FETCH_TV_TRENDING_TODAY_SUCCESS',
  FETCH_TV_TRENDING_TODAY_FAILURE: 'FETCH_TV_TRENDING_TODAY_FAILURE',
};
