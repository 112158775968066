export const PeopleActionTypes = {
  FETCH_PEOPLE_DETAILS_START: 'FETCH_PEOPLE_DETAILS_START',
  FETCH_PEOPLE_DETAILS_SUCCESS: 'FETCH_PEOPLE_DETAILS_SUCCESS',
  FETCH_PEOPLE_DETAILS_FAILURE: 'FETCH_PEOPLE_DETAILS_FAILURE',

  FETCH_PEOPLE_MOVIE_CREDITS_START: 'FETCH_PEOPLE_MOVIE_CREDITS_START',
  FETCH_PEOPLE_MOVIE_CREDITS_SUCCESS: 'FETCH_PEOPLE_MOVIE_CREDITS_SUCCESS',
  FETCH_PEOPLE_MOVIE_CREDITS_FAILURE: 'FETCH_PEOPLE_MOVIE_CREDITS_FAILURE',

  FETCH_PEOPLE_TV_CREDITS_START: 'FETCH_PEOPLE_TV_CREDITS_START',
  FETCH_PEOPLE_TV_CREDITS_SUCCESS: 'FETCH_PEOPLE_TV_CREDITS_SUCCESS',
  FETCH_PEOPLE_TV_CREDITS_FAILURE: 'FETCH_PEOPLE_TV_CREDITS_FAILURE',
};
